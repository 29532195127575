import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/oidc/oauth/oauth.service';
import Swal from 'sweetalert2';

@Injectable()
export class AuthguardGuard implements CanActivate {
  private navigationOccurred: boolean = false;
  flag: boolean = false
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(a): Observable<boolean> {
    return this.authService.IsLoggedInCheckServer().pipe(
      map((res: any) => {
         if(res.flag && res.data.isActive === true){
        const platform = res.data?.platformRole;
        if (platform?.includes('SuperAdmin') || platform?.includes('AccountAdmin')) {
          return true;
        }
        else {
          this.authService.logout(null,false);
          this.authService.configureOAuthAndLogin();
          //this.router.navigate(['/']);
          return false;
        }
      }
      else if(res.data.isActive === false)
      {
        this.DisplayUnAuthorizedPopup();
      }
      })
    );
   
  }

  DisplayUnAuthorizedPopup(){
    Swal.fire({
      title: 'In Active User',
      text: "In active user, please contact administrator!",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        this.authService.logout();
        localStorage.clear();
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/oidc/oauth/oauth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {


  constructor(private http: HttpClient, private _router: Router, private authService: AuthService) { }

  getCountries(): Observable<any> {
    const url = 'assets/countries.json';
    return this.http.get(url).pipe(
      map((data: any[]) => data.sort((a, b) => a.name.common.localeCompare(b.name.common)))
    )
  }

  creatAccount(data): Observable<any> {
    return this.http.post(environment.serviceurl + '/Registration/RegisterCustomer', data);
  }

  resendEmail(data): Observable<any> {
    return this.http.post(environment.serviceurl + '/Registration/ResendEmailOtp', data);
  }

  resendMobile(data): Observable<any> {
    return this.http.post(environment.serviceurl + '/Registration/ResendPhoneOtp', data);
  }

  verifyPhoneEmail(code): Observable<any> {
    return this.http.post(environment.serviceurl + '/Registration/VerifyPhoneEmail', code);
  }

  findRole() {
    this.authService.IsLoggedInCheckServer().subscribe((res: any) => {
        console.log("🔍 Checking User Role from Server... Response:", res);

        if (res?.flag === true && res.data != null) {
            const platform = res.data?.platformRole;

            if (platform?.includes("SuperAdmin")) {
                this._router.navigate(['admin']);
            } 
            else if (platform?.includes("AccountAdmin")) {
                this._router.navigate(['account']);
            }
            this.authService.configureOAuthAndLogin();
        } 
        else {
          
            // Ensure tokens are removed before re-authentication
            this.authService.clearOIDCTokens();
            this.authService.logout(null, false);

            console.log("🔄 Restarting Authentication...");
            this.authService.configureOAuthAndLogin();
        }
    }, error => {
        this.authService.clearOIDCTokens();
        this.authService.logout(null, false);
        this.authService.configureOAuthAndLogin();
    });
}


  navigateToHome() {
    this._router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

  checkUserName(email): Observable<any> {
    return this.http.get(environment.serviceurl + '/Registration/CheckAccountStatus/' + email);
  }

  changePhone(data): Observable<any> {
    return this.http.post(environment.serviceurl + '/Registration/ChangePhone', data);
  }

}

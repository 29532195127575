import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  issuer: 'https://tf.hyperthread.in/',
  //issuer: 'https://localhost:6500/',
  clientId: 'AccountsUIClient',
  redirectUri: window.location.origin,
  responseType: 'code',
  scope: 'openid profile email AccountsAPI offline_access',
  showDebugInformation: true
};
import { Injectable } from '@angular/core';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { authConfig } from '../oauth-config';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public http: HttpClient, private authStorage: OAuthStorage,
    private oauthService: OAuthService,
    private router: Router) { }
  UserData: any = {};

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  public configureOAuth() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocument();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  public configureOAuthAndLogin(customQueryParam:any = null, customState: any = null) {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    // Load the discovery document and initiate the login flow with custom state (if provided)
  if (customQueryParam !== null && customQueryParam !== undefined) 
  {
  this.oauthService.customQueryParams = customQueryParam;
  }
  
  if (customState !== null && customState !== undefined) 
  {
    this.oauthService.loadDiscoveryDocumentAndLogin({
    state: Object.keys(customState)
    .map(key => `${encodeURIComponent(key)}:${encodeURIComponent(customState[key])}`)
    .join(';')
  });
  } else {
    this.oauthService.loadDiscoveryDocumentAndLogin(); // No custom state, proceed with default state
  }
  
  this.oauthService.setupAutomaticSilentRefresh();
}

  clearOIDCTokens() {
    this.authStorage.removeItem('access_token');
    this.authStorage.removeItem('id_token');
  }

  login() {
    this.clearOIDCTokens();
    this.oauthService.initLoginFlow();
  }

  IsLoggedIn() {
    return this.oauthService.hasValidAccessToken();

  }

  IsLoggedInCheckServer() {
    return this.http.get(environment.serviceurl + '/Login/IsLoggedIn').pipe(map(res => res));
    //.catch('some Error');
  }

  GetOrchestratorURL(){
    return this.http.get(environment.serviceurl + '/Login/GetOrchestratorURL').pipe(map(res => res));
  }

  logout(redirectURL: any = null, redirectToLogoutpage: boolean = true) {
    localStorage.removeItem('userInfo');
		
		if (redirectURL != null && redirectURL != undefined)
			this.oauthService.postLogoutRedirectUri = redirectURL;
		
		localStorage.clear();
		
		this.oauthService.logOut();
  }

  changePassword() {
    const url = this.oauthService.issuer + 'Identity/account/manage/changepassword';
    window.open(url, '_blank');
    }

  forgotPassword(data): Observable<any> {
    return this.http.post(environment.serviceurl + '/Login/forgotpassword', JSON.stringify(data)).pipe(map(res => res))
  }

  setUserData(data) {
    this.UserData = data;
  }
}

import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './oidc/oauth/oauth.service';
import { Globals } from './globals/globals';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { AuthApiService } from 'src/app/core/services/auth/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	params: any;
	excludedPaths = ["/register","/onetimepassword"]
	queryParamsSubscription: Subscription;
	constructor(private authService: AuthService,
		private _router: Router, private oauthService: OAuthService,
		public globals: Globals
		, private route: ActivatedRoute, private authStorage: OAuthStorage
		, private location: Location, private authApiService:AuthApiService) {

	}
	
	ngOnInit(): void {
		
		this.queryParamsSubscription = this.route.queryParams.subscribe(params => {

			this.params = params;
		});
		
		this.oauthService.events.subscribe(event => {
			if (event.type === 'token_refresh_error' || event.type === 'invalid_nonce_in_state') {
				this.authService.login();
			}
			else if (event.type === 'token_received') {
				if (this.globals.userData == undefined ||
					this.globals.userData == null) {
					this.oauthService.loadUserProfile().then(() => {
						let claims = this.oauthService.getIdentityClaims();
						if (claims) {
							this.globals.userData = claims;
							if (this.params != null) {
								if (this.params["Role"] != undefined && this.params["Role"].includes("SuperAdmin")) {
									this.queryParamsSubscription.unsubscribe();
									this._router.navigate(['admin'])
								}
								else if (this.params["Role"] != undefined && this.params["Role"].includes("AccountAdmin")) {
									this.queryParamsSubscription.unsubscribe();
									this._router.navigate(['account'])
								}
							}
						}
					});
				}
			}
			else if (event.type === 'user_profile_loaded') {
			
			}
		});
		console.log("🔍 Is User Logged In?", this.authService.IsLoggedIn());
			if (!this.authService.IsLoggedIn()) {
				if (!this.excludedPaths.includes(this.location.path())) {
					this.authService.configureOAuthAndLogin();
				}
			}
			else {
				this.authApiService.findRole();
			}
	}

	

}
import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals/globals';
import { AuthService } from 'src/app/oidc/oauth/oauth.service';


@Component({
  selector: 'app-account-admin',
  templateUrl: './account-admin.component.html',
  styleUrls: ['./account-admin.component.scss']
})
export class AccountAdminComponent implements OnInit {
  userName: any;
  showLogout: boolean;
  nameSingleValue: string;
  isCondensed: any;
  orchURL: string;
  constructor(private authService: AuthService, public globals: Globals) { }
  ngOnInit(): void {
    this.GetOrchestratorURL();
    this.userName = JSON.parse(sessionStorage.getItem('id_token_claims_obj')).email;
    this.nameSingleValue = this.userName.charAt(0);

  }

  onDropShow() {
    this.showLogout = this.showLogout ? false : true;
  }

  changePsw() {
    this.authService.changePassword();
  }

  onLogout() {
    this.authService.logout(null, false);
    localStorage.clear();
  }
  selectHub(){
    // Redirect to the orchestrator URL
    if (this.globals.orchestratorURL) {
        window.location.href = this.globals.orchestratorURL;
      } else {
      console.error('Orchestrator URL is not set.');
    }
  }

  GetOrchestratorURL(){
    this.authService.GetOrchestratorURL().subscribe(
      (response: any) => {
        if (response.flag) {
          this.orchURL = response.data; 
          this.globals.orchestratorURL = response.data;
        } else {
          console.error('Failed to get orchestrator URL:', response.message);
        }
      },
      (error) => {
        console.error('Error fetching orchestrator URL:', error);
      }
    );
  }

  toggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    const currentSIdebarSize = document.body.getAttribute("data-sidebar-size");
    if (window.screen.width >= 992) {
      if (currentSIdebarSize == null) {
        (document.body.getAttribute('data-sidebar-size') == null || document.body.getAttribute('data-sidebar-size') == "lg") ? document.body.setAttribute('data-sidebar-size', 'sm') : document.body.setAttribute('data-sidebar-size', 'lg')
      } else if (currentSIdebarSize == "md") {
        (document.body.getAttribute('data-sidebar-size') == "md") ? document.body.setAttribute('data-sidebar-size', 'sm') : document.body.setAttribute('data-sidebar-size', 'md')
      } else {
        (document.body.getAttribute('data-sidebar-size') == "sm") ? document.body.setAttribute('data-sidebar-size', 'lg') : document.body.setAttribute('data-sidebar-size', 'sm')
      }
    }
    this.isCondensed = !this.isCondensed;
  }

}
